export const SUBSCRIPTION_CHAT_EVENTS_TYPING_FRAGMENT = `
  fragment subscriptionChatEventsTypingFragment on TypeEventOutput {
    event
    groupId
    user {
    uid
    firstName
    lastName
    jobTitle
      pictureFileResource {
        schemaCode
        path
      }
    }
  }
`;
